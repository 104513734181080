@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme-reset($theme) {
    $color-config:  mat.get-color-config($theme);
    $primary:       map.get($color-config, 'primary');
    $accent:        map.get($color-config, 'accent');
    $warn:          map.get($color-config, 'warn');
    $foreground:    map.get($color-config, 'foreground');
    $background:    map.get($color-config, 'background');

    .bg-primary {
        background: mat.get-color-from-palette($primary);
        color: mat.get-color-from-palette($primary, default-contrast);
    }
    .bg-accent {
        background: mat.get-color-from-palette($accent);
        color: mat.get-color-from-palette($accent, default-contrast);
    }
    .bg-warn {
        background: mat.get-color-from-palette($warn);
        color: mat.get-color-from-palette($warn, default-contrast);
    }

    .text-muted {
        color: mat.get-color-from-palette($foreground, secondary-text) !important;
    }

    .secondary-color{
        color: mat.get-color-from-palette($primary, 100);
    }

    .primary-color{
        color: mat.get-color-from-palette($primary);
    }

    .top-navbar.mat-toolbar,
    .app-dropdown .mat-menu-item,
    .app-dropdown .user-info,
    .account-sidenav .mat-nav-list .mat-list-item,
    .product-item .title,
    .info-bar .mat-card .content p,
    .filter-sidenav .mat-expansion-panel-header-title,
    .mat-tab-body-content,
    .account-card-title {
         color: mat.get-color-from-palette($accent, darker) !important;
    }

    .top-toolbar.mat-toolbar-row{
        border-bottom: 1px solid mat.get-color-from-palette($primary, lighter, 0.2);
        color: mat.get-color-from-palette($primary, 100);
        .top-menu a{
            color: mat.get-color-from-palette($primary, 100);
        }
    }

    .top-navbar{
       // background: mat.get-color-from-palette($primary, lighter);
       background: mat.get-color-from-palette($background, background);
    }

    .border-bottom-mute{
        border-bottom: 1px solid mat.get-color-from-palette($primary, lighter, 0.2);
    }


    .search-dropdown.mat-menu-panel{
        background: transparent;
    }


    .mat-snack-bar-container.success {
        background: #388E3C;
    }
    .mat-snack-bar-container.error {
        background: #E53935;
    }

    .new-price{
        color: mat.get-color-from-palette($warn);
    }
    .primary-text{
        color: mat.get-color-from-palette($primary);
    }

    .active-link{
        background-color: mat.get-color-from-palette($primary);
        color: #fff;
    }
    .horizontal-active-link,
    .app-dropdown .mat-menu-item.horizontal-active-link{
        color: mat.get-color-from-palette($primary) !important;
    }

    .filter-brands  button.selected,
    .filter-buttons button.selected{
        box-shadow: 0px 0px 1px 2px mat.get-color-from-palette($primary);
    }

    /* ngx-pagination */
    .product-pagination .ngx-pagination{
        margin-bottom: 0;
        padding: 14px 10px;
        .current {
            background: mat.get-color-from-palette($primary);
            color: mat.get-color-from-palette($primary, default-contrast);
        }
        a:hover, button:hover{
            background: rgba(mat.get-color-from-palette($primary), 0.2);
            color: mat.get-color-from-palette($foreground, base);
        }
    }


    // admin styles
    .breadcrumb{
        a{
            color: mat.get-color-from-palette($primary);
        }
        .breadcrumb-item+.breadcrumb-item:before{
            color: mat.get-color-from-palette($foreground, text);
        }
    }
    .user-block{
        border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);
    }

    .menu-item .mat-button:not(.active-link) .menu-icon{
        color: mat.get-color-from-palette($primary);
    }

}
// New Changes
mat-toolbar#menu-toolbar {
    background: #EEEEEE;
}
.nav_icons {
    padding: 0 10px;
}
@media (min-width: 650px) {
    mat-toolbar-row.mat-toolbar-row.top-toolbar.theme-container {
        display: none !important;
    }
}
 mat-icon.mat-icon.notranslate.mat-icon-lg.material-icons.mat-icon-no-color {
    color: black;
}
app-top-menu {
    display: none;
}
button.mat-focus-indicator.sidenav-toggle.mat-button.mat-button-base {
    color: #5c4545;
}


mat-toolbar#header-toolbar {
    background: #eeeeee;
    padding-top: 20px;
}
a.logo {
    color: #464545;
}
.text-center.py-2.ng-tns-c257-1 {
    display: flex;
    justify-content: center;
}
.sidebarnav{
    display: flex;
    justify-content: center;
}
@media (max-width: 650px) {
.burgericon{
    display: none;
}
}
button.mat-focus-indicator.swiper-button-next.swipe-arrow.mat-mini-fab.mat-button-base.mat-primary {
    background: #e5717c;
}
button.mat-focus-indicator.swiper-button-prev.swipe-arrow.mat-mini-fab.mat-button-base.mat-primary {
    background: #e5717c;
}
button.mat-focus-indicator.mat-raised-button.mat-button-base.mat-primary {
    background: #e5717c;
}
.mat-tab-label-content {
    font-size: 20px;
    color: black;
    font-weight: 600;
}
button.mat-focus-indicator.swiper-button-next.swipe-arrow.mat-mini-fab.mat-button-base.mat-accent {
    background: #e5717c;
    color: white;
}
button.mat-focus-indicator.swiper-button-prev.swipe-arrow.mat-mini-fab.mat-button-base.mat-accent {
    background: #e5717c;
    color: white;
}
.product_name {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
button.mat-focus-indicator.mat-tooltip-trigger.add_tocart.mat-icon-button.mat-button-base.ng-star-inserted {
    background: #2EBB55;
    color: white;
}
.product_card_icon {
    display: flex;
    justify-content: flex-end;
}
.eye_quick {
    position: absolute;
    background: #f3f3f3;
    border-radius: 8px;
    height: 30px;
    display: flex;
    align-items: center;
}
.brands-carousel {
    background-color: transparent !important;
}
.prices.new_align {
    flex-direction: column !important;
    align-items: flex-end !important;
}
// mat-ink-bar.mat-ink-bar {
//     background: white !important;
// }
a.mat-focus-indicator.mat-button.mat-button-base.horizontal-active-link {
    color: #e5717c !important;
}
mat-sidenav.mat-drawer.mat-sidenav.filter-sidenav.ng-tns-c257-9.ng-trigger.ng-trigger-transform.ps.mat-drawer-side.mat-drawer-opened.ng-star-inserted {
    background: #fafafa;
}
mat-card.mat-card.mat-focus-indicator.p-0.text-center {
    box-shadow: none !important;
    background: #fafafa;
}

mat-card.mat-card.mat-focus-indicator.product-item.text-center {
    min-height: 356px;
}
mat-sidenav.mat-drawer.mat-sidenav.filter-sidenav.ng-tns-c257-11.ng-trigger.ng-trigger-transform.ps.mat-drawer-side.mat-drawer-opened.ng-star-inserted {
    background: #fafafa;
}

// .ngx-pagination .disabled {
//     background: #fafafa !important;
// }
// .ngx-pagination a, .ngx-pagination button {
//     color: #cfcfcf !important;
// }
.text-muted.view_det {
    place-content: center !important;
}
button.mat-focus-indicator.mat-tooltip-trigger.view_det_btn.mat-icon-button.mat-button-base {
    width: fit-content;
    color: #5C59F5;
}
.disc_price {
    display: flex;
    align-items: center;
    margin: 18px 0;
}
mat-chip.mat-chip.mat-focus-indicator.disc_txt.mat-standard-chip.mat-warn.mat-chip-selected {
    background: white;
    color: #ff8282;
    font-size: 16px;
}
h2.mt-2.new-price.new_disc_price {
    color: #080808;
    font-size: 25px;
    font-weight: 400;
}
.mat-chip-list-wrapper {
    justify-content: center;
}
button.wishlist_btn {
    background: #ef677f;
    color: white;
    padding: 15px 24px;
    border: 0;
    border-radius: 5px;
}
button.add_btn {
    background: #1565c0;
    color: white;
    padding: 15px 42px;
    border: 0;
    border-radius: 5px;
}
.ngx-pagination .pagination-next a::after, .ngx-pagination .pagination-next.disabled::after {
    content: url(https://i.postimg.cc/PqcqnHnQ/Icon.png) !important;
}
.ngx-pagination .pagination-previous a::before, .ngx-pagination .pagination-previous.disabled::before {
    content: url(https://i.postimg.cc/4dNQqzZ9/Icon-1.png) !important;
}
.moq{
    width: 183px;
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    color: #a2a2a3;
}
.tabs_n {
    max-width: 350px;
    box-shadow: 0px 2px 11px 0px rgb(0 0 0 / 20%), 0px 1px 1px -2px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    margin: 30px;
    border-radius: 9px;
    padding: 23px 0 0 0;
}
.tabs_n img{
    width: 189px;
    padding: 15px;
}
.tabs_n h3{
    text-align: center;
    padding: 18px 0;
    font-size: 20px;
}
.cat_header h3 {
    text-align: end;
}
.cat_container{
    margin: 10px 73px;
}
.cat_tabs {
    display: flex;
    justify-content: space-evenly;
}
.mobile_tab {
    display: flex;
}
@media (max-width: 650px) {
    .cat_tabs{
    flex-direction: column;
}
.cat_container {
    margin: 10px 0px;
}
.tabs_n {

    display: flex;
    flex-direction: column;
    align-items: center;
}
.tabs_n h3 {
    text-align: center;
    padding: 0px 0px 9px;
    font-size: 15px;
}
.tabs_n img {
    width: 76px;
    padding: 15px;
}
.tabs_n {
    width: 132px;
    margin: 10px 13px;
    padding: -2px 0 0 0;
}
button.mat-focus-indicator.mat-menu-trigger.drop_btn.mat-button.mat-button-base {
    font-size: 15px!important;
}
.cat_tabs {

    align-items: center;
}
}
.wishlist_icn {
    display: flex;
    justify-content: flex-end;
}
button.mat-focus-indicator.mat-menu-trigger.drop_btn.mat-button.mat-button-base {
    font-size: 20px;
}
